<script setup lang="ts">
import { computed } from '#imports'

const props = defineProps<{
  modelValue?: string[]
  label: string
  required?: boolean
}>()

defineEmits(['click'])

const isActive = computed(() => props.modelValue && props.modelValue.length)
const extraCount = computed(() =>
  isActive.value ? props.modelValue?.length! - 1 : 0,
)
</script>

<template>
  <button
    class="inline-flex h-full max-h-10 items-center justify-center space-x-2 rounded-full border border-neutral-200 px-3 py-2"
    :class="{
      'bg-secondary-50 text-secondary-800 border-transparent': isActive,
      'text-neutral-800': !isActive,
    }"
    type="button"
    @click="$emit('click')"
  >
    <span class="subtitle-2 flex">
      <slot name="icon" />
      <span class="max-w-xs truncate whitespace-nowrap">
        {{
          modelValue && modelValue.length ? `${label}: ${modelValue[0]}` : label
        }}
      </span>
      <span v-if="extraCount">, +{{ extraCount }}</span>
      <span class="i-ph-caret-down-bold ml-2 h-4 w-4 rounded-full" />
    </span>
  </button>
</template>
